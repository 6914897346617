import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export interface UserByGroupModel {
  parentId?: string;
  userId?: string;
  uid?: number;
  phone?: string;
  email?: string;
  name?: string;
  inviteCode?: string;
  isAgent?: boolean;
  createdDate?: string;
}

export const UserByGroupApi = {
  findAll: async (): Promise<UserByGroupModel[] | undefined> => {
    const { data } = await fetchJson(`${API_URL}/userByGroup`, {
      method: "GET",
    });
    return data;
  },
};
