import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { CreditCard } from "@mui/icons-material";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { CommissionTypeValueEnum } from "../../model/PayTasks";

/*
@Indexed
    val userId: ObjectId,

    val prickDate: LocalDateTime,
    val orderIndex: Int,
    // 打针模式
    var commissionType: CommissionType,
    // 打针幅度/补金金额 不能小于1.1
    //  余额比例：用户余额 x 打针幅度 x 连单比例 = 订单金额
    //  补金模式：用户余额 + 补金金额 = 订单金额
    @Field(targetType = FieldType.DECIMAL128)
    val amount: BigDecimal = BigDecimal.ZERO,

    @Field(targetType = FieldType.DECIMAL128)
    val bonusBalance: BigDecimal = BigDecimal.ZERO,

 */

const fields: MyField[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.editDate({
    label: "打针日期",
    source: "prickDate",
    helperText:
      "当用户加入叠加组时，打针日期不生效，叠加组会根据订单来判定生效",
  }),
  Field.editNumber({
    label: "打针订单",
    source: "orderIndex",
    helperText: "0=下一个订单",
  }),
  Field.select({
    label: "打针模式",
    source: "commissionType",
    isFilter: true,
    choices: CommissionTypeValueEnum,
  }),
  Field.amount({
    label: "打针幅度/补金金额",
    source: "amount",
    helperText:
      "余额比例：用户余额 x 打针幅度 x 连单比例 = 订单金额 <br>" +
      "补金模式：用户余额 + 补金金额 = 订单金额",
  }),
  Field.amount({
    label: "奖励佣金",
    source: "bonusBalance",
    helperText:
      "奖励佣金：在用户得到基础佣金（订单金额x佣金比例]）的前提下，额外奖励一定金额佣金给用户",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

export const UserPrickNeedleCom = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  create: (record: any, onSuccess: (data: any, variables: any) => void) => {
    return (
      <MyCreate
        resource={"userPrickNeedle"}
        fields={fields}
        record={record}
        redirect={false}
        mutationOptions={{ onSuccess: onSuccess }}
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CreditCard />,
};

export default resource;
