import { Field } from "../Field";

export function userLevelField(
  originSourceName: string = "userLevelId",
  sourceName: string = "userLevelId"
) {
  return Field.ref({
    label: "会员级别",
    source: sourceName,
    reference: "userLevelSetting",
    referenceLabel: "name",
    originSource: originSourceName,
    minWidth: 140,
  });
}
