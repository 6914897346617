import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { DateField, DateInput } from "react-admin";

export interface DataArgs {
  label: string;
  source: string;
}

export function data(args: DataArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,
      component: DateField,
      editComponent: DateInput,
      isFilter: false,
      isEdit: false,
      props: {
        showTime: false,
      },
    },
    field
  );
}
