import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { OrderManagerApi } from "../../api/OrderManagerApi";
import ConfirmDialog from "../core/ConfirmDialog";

function ConfirmPayBtn({ record }: { record: any }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await OrderManagerApi.confirmPayment(record.id);
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"info"}
        onClick={handleClickOpen}
      >
        强制付款
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"操作确认"}
        description={"强制付款不会产生佣金， 也不会扣余额"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default ConfirmPayBtn;
