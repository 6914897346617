import {
  Box,
  Button as MuiButton,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { FormEvent, useEffect, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import {
  UserAddressForm,
  UserAddressManagerApi,
} from "../../../api/UserAddressManagerApi";

function EditBankCardBtn({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [localUserId, setLocalUserId] = useState(userId);

  const defaultEntity = {
    userId: localUserId,
  } as UserAddressForm;

  const [formData, setFormData] = useState<UserAddressForm>(defaultEntity);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  const handleClickOpen = async () => {
    const data = await UserAddressManagerApi.findByUserId(localUserId);
    if (data) {
      setFormData(data as UserAddressForm);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    await UserAddressManagerApi.update(formData);
    notify("操作成功");
    handleClose();
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        收获地址
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">收获地址信息</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={onSubmit}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={formData.realName}
                    onChange={(e) => {
                      setFormData({ ...formData, realName: e.target.value });
                    }}
                    margin="dense"
                    size="medium"
                    label="收货姓名"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    required
                    value={formData.telephone}
                    onChange={(e) => {
                      setFormData({ ...formData, telephone: e.target.value });
                    }}
                    margin="dense"
                    size="medium"
                    label="电话号"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    required
                    value={formData.region}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        region: e.target.value,
                      });
                    }}
                    margin="dense"
                    size="medium"
                    label="收货地区"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    required
                    value={formData.address}
                    onChange={(e) => {
                      setFormData({ ...formData, address: e.target.value });
                    }}
                    margin="dense"
                    size="medium"
                    label="详细地址"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default EditBankCardBtn;
