import { MyField } from "../../components/types";
import { FunctionField } from "react-admin";
import { base, BaseArgs } from "./base";
import { format } from "../percent";
import get from "lodash/get";
import { PercentInput } from "../../components/PercentInput";
export interface PercentArgs extends BaseArgs {}

const defaultArgs: Partial<PercentArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function percent(args: PercentArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      editComponent: PercentInput,
      isFilter: false,
      sortable: false,
      props: {
        textAlign: "right",
        render: (record: any, source: string) =>
          format(get(record, source as string)) + " %",
      },
      editProps: {
        step: 0.01,
      },
    },
    field
  );
}
