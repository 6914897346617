export enum PayChannelType {
  /**
   * 自己的接口
   */
  MY = "MY",
}

export const PayChannelTypeChoices = [
  { id: PayChannelType.MY, name: "自己的接口" },
];
