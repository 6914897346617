import { Box, InputLabel } from "@mui/material";
import { RaRecord } from "ra-core";
import type { EditProps } from "ra-ui-materialui/src/types";
import { Edit, SimpleForm } from "react-admin";
import { ExcludeProps, MyField } from "../types";
import omit from "lodash/omit";
import * as React from "react";

export interface MyEditProp<RecordType extends RaRecord = any>
  extends EditProps<RecordType> {
  fields: MyField<RecordType>[];
  maxWidth?: number | string;
}

const MyEdit = (props: MyEditProp) => {
  const { fields, maxWidth = "100%", ...rest } = props;
  return (
    <Edit mutationMode="pessimistic" {...rest} title={"sb"}>
      <SimpleForm sx={{ maxWidth: maxWidth, minWidth: "800px" }}>
        {fields
          .filter((f) => f.isEdit && f.editComponent)
          .map((field: MyField, index) => {
            const {
              editComponent: FieldComponent,
              component: ShowComponent,
              source,
              label,
              editProps,
              props,
              isUpdate,
            } = field;

            if (!FieldComponent) {
              return <></>;
            }

            const curProps = {
              ...editProps,
            };

            if (FieldComponent === Box) {
              return (
                <FieldComponent
                  key={`${index}`}
                  {...omit(curProps, ExcludeProps)}
                />
              );
            }
            if (!isUpdate) {
              if (!ShowComponent) {
                return null;
              }
              const showProps = {
                ...props,
              };
              return (
                <Box
                  key={`${index}`}
                  p={"8px 0"}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <InputLabel sx={{ marginRight: "7px" }}>{label}:</InputLabel>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <ShowComponent
                      key={`${index}`}
                      source={source}
                      label={label}
                      {...omit(showProps, ["minWidth", "maxWidth"])}
                    />
                  </Box>
                </Box>
              );
            }
            return (
              <FieldComponent
                key={`${index}`}
                source={source}
                label={label}
                {...omit(curProps, ["minWidth", "maxWidth"])}
              />
            );
          })}
      </SimpleForm>
    </Edit>
  );
};

export default MyEdit;
