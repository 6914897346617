import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import UserRisk from "../../pages/order/UserRisk";

interface RiskListDialogBtnProps {
  userId: string;
  riskScore: number;
}

function RiskListDialogBtn({ userId, riskScore }: RiskListDialogBtnProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSuccess = () => {
    handleClose();
  };

  return (
    <>
      <Button
        variant="text"
        size={"large"}
        color={riskScore > 100 ? "error" : "primary"}
        onClick={handleClickOpen}
      >
        {`风险[${riskScore}]`}
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"lg"}
          onClose={handleClose}
          scroll="paper"
        >
          {UserRisk.list(userId)}
        </Dialog>
      )}
    </>
  );
}

export default RiskListDialogBtn;
