import VisitorIcon from "@mui/icons-material/People";
import omit from "lodash/omit";
import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  maxLength,
  minLength,
  required,
  SelectInput,
  TextField,
  TextInput,
  WrapperField,
} from "react-admin";
import { AdminManagerApi } from "../api/AdminManagerApi";
import { EditSelectField } from "../components/EditSelectField";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { MyList } from "../components/core/MyList";
import { MyField } from "../components/types";
import { UnLockButton } from "../components/UnLockButton";
import { Admin } from "../model/Admin";
import { BooleanTypeChoices, StatusTypeChoices } from "../model/Core";
import { Field } from "../utils/Field";
import { MyShow } from "../components/core/MyShow";
import { MyShowFields } from "../components/MyShowFields";
import React from "react";

const fields: MyField<Admin>[] = [
  Field.objectId(),
  {
    label: "会员名",
    source: "name",
    sortable: true,
    isPrimary: true,
    isFilter: true,
    component: TextField,
    filterProps: {
      alwaysOn: true,
      clearAlwaysVisible: true,
      resettable: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: false,
    editComponent: TextInput,
    editProps: {
      validate: [required(), minLength(4), maxLength(20)],
    },
  },
  Field.ref({
    label: "角色",
    source: "role",
    reference: "sysRoles",
    referenceLabel: "name",
    originSource: "role",
    isFilter: false,
  }),
  {
    label: "密码",
    source: "password",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "注册时间",
    sortable: true,
    source: "createdDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
  Field.select({
    label: "是否控盘账号",
    source: "isRobotAdmin",
    choices: BooleanTypeChoices,
  }),
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: true,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      choices: StatusTypeChoices,
      validate: [required()],
    },
  },
  {
    label: "锁定状态",
    source: "lock",
    component: WrapperField,
    isFilter: true,
    props: {
      children: (
        <UnLockButton
          onClick={async (r: any) => AdminManagerApi.unlock(r.id)}
        />
      ),
    },
  },
];

const resource = {
  hasDelete: false,
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />, <DeleteWithConfirmButton />]}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        fields={fields}
        transform={(data) => {
          if (data?.password?.length === 0) {
            return omit(data, ["password"]);
          }
          return data;
        }}
      />
    );
  },
  icon: <VisitorIcon />,
};

export default resource;
