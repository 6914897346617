import omit from "lodash/omit";
import {
  DateField,
  maxLength,
  minLength,
  required,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TextInputProps,
  WrapperField,
} from "react-admin";
import { UserManagerApi } from "../api/UserManagerApi";
import { EditSelectField } from "../components/EditSelectField";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { MyList } from "../components/core/MyList";
import { MyField } from "../components/types";
import { UnLockButton } from "../components/UnLockButton";
import { Admin } from "../model/Admin";
import { BooleanTypeChoices, StatusTypeChoices } from "../model/Core";
import { Field } from "../utils/Field";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { MyEditButton } from "../components/core/MyEditButton";
import { ResourceType } from "../components/core/MyResource";
import UserShowCom from "../components/userShowCom/UserShowCom";

function MyTextInput(props: TextInputProps & { localLabelSource: string }) {
  return <TextInput {...omit(props, "localLabelSource")} />;
}

export let fields: MyField<Admin>[];
fields = [
  Field.objectId({
    label: "用户ID",
    isList: false,
  }),
  Field.localRef(
    {
      label: "uid",
      source: "uid",
      isFilter: true,
      isEdit: true,
      isCreate: false,
      isUpdate: false,
      reference: "users",
      referenceLabel: "uid",
      originSource: "id",
      localLabelSource: "uid",
    },
    {
      filterProps: {
        source: "uid",
      },
      editComponent: MyTextInput,
    }
  ),
  Field.text({
    label: "邀请码",
    source: "inviteCode",
    isEdit: true,
    isCreate: false,
    isUpdate: false,
  }),
  Field.editSelect({
    label: "是否代理商",
    source: "isAgent",
    choices: BooleanTypeChoices,
    defaultValue: false,
  }),
  Field.editSelect({
    label: "是否锁定账户",
    source: "isAccountLock",
    defaultValue: false,
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否假人",
    source: "isMyUser",
    defaultValue: false,
    choices: BooleanTypeChoices,
  }),
  Field.text({
    label: "账号",
    source: "name",
    isEdit: true,
    isCreate: true,
    isUpdate: true,
  }),
  Field.text({
    label: "邮箱",
    source: "email",
    isUpdate: false,
  }),
  Field.text({
    label: "电话",
    source: "phone",
    isUpdate: false,
  }),
  {
    label: "备注",
    source: "desc",
    sortable: false,
    isPrimary: true,
    isFilter: false,
    component: TextField,
    props: {},
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      idate: [maxLength(512)],
    },
  },
  {
    label: "密码",
    source: "password",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      idate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "超级密码",
    source: "adminPassword",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      idate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "注册时间",
    sortable: true,
    source: "createdDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: false,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      defaultValue: "NORMAL",
      choices: StatusTypeChoices,
      idate: [required()],
    },
  },
  {
    label: "登录锁定",
    source: "lock",
    component: WrapperField,
    isFilter: true,
    props: {
      children: (
        <UnLockButton onClick={async (r: any) => UserManagerApi.unlock(r.id)} />
      ),
    },
  },
];

const resource: ResourceType = {
  show: () => {
    return <UserShowCom fields={fields} />;
  },
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<MyEditButton />, <ShowButton />]} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          const omits = [];
          if (data?.password?.length === 0) {
            omits.push("password");
          }
          if (data?.adminPassword?.length === 0) {
            omits.push("adminPassword");
          }
          return omit(data, omits);
        }}
        fields={fields}
      />
    );
  },
  icon: <PermContactCalendarIcon />,
};

export default resource;
