import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin } from "../model/Admin";

export const UserLevelRecordApi = {
  currentLevelRecord: async (id: string): Promise<Admin> => {
    const { data } = await fetchJson(
      `${API_URL}/userLevelRecord/currentLevelRecord/${id}`,
      {
        method: "GET",
      }
    );
    return data;
  },
  startMakeOrder: async (userId: string): Promise<Admin> => {
    const { data } = await fetchJson(
      `${API_URL}/userLevelRecord/startMakeOrder/${userId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  findByUserId: async (userId: string): Promise<UserMakeOrderState> => {
    const { data } = await fetchJson(
      `${API_URL}/userLevelRecord/findByUserId/${userId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};

export interface UserMakeOrderState extends Record<string, any> {
  id?: string;
  userId?: string;
  currUserLevelId?: number;
  draftPayTasksId?: number;
  // 管理后台设置开始做单
  startMarkOrder?: boolean;

  currentPayTasksId?: number;

  currMarkOrderIndex?: number;

  currOrderId?: number;

  // 当前payTasks 是否完成了， 如果完成就需要重新管理员发起做单
  currPayTasksIsEnd?: boolean;

  /**
   * 任务做到第几轮了
   */
  totalPayTasksCount?: number;
}

export interface UserMakeOrderStateForm extends Record<string, any> {
  userId: string;
  currUserLevelId?: number;
  currentPayTasksId?: number;
  /** 任务做到第几轮了 */
  totalPayTasksCount?: number;
}
