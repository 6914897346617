import { Box } from "@mui/material";
import React from "react";
import { AgentStatisticsModel } from "../model/DashboardJsonView";
import { DashboardApi } from "../api/DashboardApi";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

function AgentByGroup() {
  const [data, setData] = React.useState<AgentStatisticsModel[]>([]);

  const fetchData = async () => {
    const res =
      (await DashboardApi.getAgentByDateRange({
        startDate: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      })) || [];

    const tmpDate: AgentStatisticsModel[] = [];
    res
      .filter((r) => !r.parentUserId)
      .forEach((r) => {
        tmpDate.push(r);
        res
          .filter((cr) => cr.parentUserId === r.userId)
          ?.forEach((cr) => {
            cr.isChildren = true;
            tmpDate.push(cr);
          });
      });
    setData(tmpDate);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    { field: "userId", headerName: "ID", width: 70 },
    { field: "uid", headerName: "uid", width: 130 },
    {
      field: "name",
      headerName: "name",
      width: 130,
      valueGetter: (value, row) => (row.isChildren ? `------ ${value}` : value),
    },
    {
      field: "totalUsers",
      headerName: "累计用户",
      type: "number",
      width: 90,
    },
    {
      field: "totalOrderAmount",
      headerName: "累计订单金额",
      width: 180,
    },
    {
      field: "todayRechargeAmount",
      headerName: "今日充值",
      width: 180,
    },
    {
      field: "totalRechargeAmount",
      headerName: "累计充值",
      width: 180,
    },
    {
      field: "todayWithdrawAmount",
      headerName: "今日提现",
      width: 180,
    },
    {
      field: "totalWithdrawAmount",
      headerName: "累计提现",
      width: 180,
    },
  ];

  return (
    <div style={{ minHeight: "500px", width: "100%" }}>
      <DataGrid getRowId={(row) => row.userId} rows={data} columns={columns} />
    </div>
  );
}

export default AgentByGroup;
