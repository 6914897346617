import {
  Box,
  Button as MuiButton,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { FormEvent, useEffect, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import {
  deduct,
  recharge,
} from "../../../service/adminUserWallet/api/adminUserWalletService";
import { DEFAULT_WALLET_COIN, DEFAULT_WALLET_TYPE } from "../../../config";
import { UserWalletManagerApi } from "../../../api/UserWalletManagerApi";

function AddBonusBtn({ userId }: { userId: string }) {
  const [number, setNumber] = useState("0");
  const [remark, setRemark] = useState("");
  const [localUserId, setLocalUserId] = useState(userId);
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [balance, setBalance] = useState("0");

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  const resetForm = () => {
    setNumber("0");
    setRemark("");
  };

  const handleClickOpen = async () => {
    const data = await UserWalletManagerApi.findByUserId(localUserId);
    if (data) {
      setBalance(data.balance);
    }
    resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (remark.length <= 0) {
      notify("备注不能为空", { type: "warning" });
      return;
    }
    const amount = Number(number);
    if (amount > 0) {
      await recharge({
        userId: localUserId,
        type: DEFAULT_WALLET_TYPE,
        amount: number,
        coinSymbol: DEFAULT_WALLET_COIN,
        remark: remark,
      });
    } else if (amount < 0) {
      await deduct({
        userId: localUserId,
        amount: `${-amount}`,
        type: DEFAULT_WALLET_TYPE,
        coinSymbol: DEFAULT_WALLET_COIN,
        remark: remark,
      });
    } else {
      notify("金额不能等于0", { type: "warning" });
      return;
    }

    notify("操作成功");
    handleClose();
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        彩金
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">彩金</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={onSubmit}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item flex={1}>
                  <TextField
                    label={"余额"}
                    value={balance}
                    disabled
                    variant="standard"
                    size="medium"
                  />
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    margin="dense"
                    size="medium"
                    id="url"
                    label="请输入金额"
                    type="number"
                    fullWidth
                    variant="standard"
                    helperText="添加彩金必须大于0， 提现彩金必须小于0"
                  />
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    margin="dense"
                    size="medium"
                    id="url"
                    label="请输入备注"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default AddBonusBtn;
