import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export interface UserBankCardForm extends Record<string, any> {
  id?: string;
  userId?: string;
  // 收款国家
  recipientCountry?: string;
  // 币种
  symbol?: string;
  // 账户名
  accountName?: string;
  // 账户号 / IBAN
  accountNumber?: string;
  // SWIFT/BIC
  accountCode?: string;
  // 企业名称
  enterpriseName?: string;
  // 收款方信息
  cashierInformation?: string;
  // 名
  firstName?: string;
  // 姓
  lastName?: string;
}

export interface UserBankCard extends UserBankCardForm {
  createdDate: string;
  updateDate?: string;
}

export const UserBankCardManagerApi = {
  findByUserId: async (userId: string): Promise<UserBankCard> => {
    const { data } = await fetchJson(
      `${API_URL}/userBankCard/findByUserId/${userId}`,
      {
        method: "GET",
      }
    );
    return data;
  },
  update: async (form: UserBankCardForm): Promise<UserBankCard> => {
    const { data } = await fetchJson(`${API_URL}/userBankCard/update`, {
      method: "POST",
      body: new URLSearchParams(form),
    });
    return data;
  },
};
