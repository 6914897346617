import Box from "@mui/material/Box";
import React, { useState } from "react";

import {
  MenuItemLinkClasses,
  MenuProps,
  useAuthState,
  useSidebarState,
} from "react-admin";

import SubMenu from "./layout/SubMenu";
import { useChildMenu, useMenus } from "./hook/MenuCentext";
import { Theme, useMediaQuery } from "@mui/material";
import isUndefined from "lodash/isUndefined";
import { styled } from "@mui/material/styles";
import { MyMenuItemLink } from "./components/core/MyMenuItemLink";
import { AccountStorage } from "./AccountStorage";

interface MenuPanelProps {
  open: boolean;
}

const MenuPanel = styled(Box)<MenuPanelProps>(({ theme, open }) => ({
  width: open ? 200 : 50,
  marginTop: 1,
  marginBottom: 1,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [`& .${MenuItemLinkClasses.active}`]: {
    backgroundColor: theme.palette.action.selected,
  },
}));
const Menu = ({ dense = false }: MenuProps) => {
  const childMenu = useChildMenu();
  const menus = useMenus();
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("md")
  );
  const [state, setState] = useState<Record<string, boolean>>({});
  const [open] = useSidebarState();
  const handleToggle = (menu: string) => {
    setState((state) => {
      let b = !state[menu];
      return { ...state, [menu]: isUndefined(state[menu]) ? false : b };
    });
  };
  if (!isLargeEnough) {
    return (
      <MenuPanel open={open}>
        {menus.map((menu) => {
          return (
            <SubMenu
              key={menu.name}
              handleToggle={() => handleToggle(menu.name)}
              isOpen={isUndefined(state[menu.name]) ? true : state[menu.name]}
              name={menu.name}
              icon={menu.icon!!}
              dense={dense}
            >
              {menu?.children
                ?.filter((r) => r.href)
                ?.filter((r) => AccountStorage.hasModule(r.code))
                ?.map((menu) => {
                  return (
                    <MyMenuItemLink
                      key={menu.name}
                      to={menu.href!}
                      state={{ _scrollToTop: true }}
                      primaryText={menu.name}
                      leftIcon={menu.icon}
                      dense={dense}
                    />
                  );
                })}
            </SubMenu>
          );
        })}
      </MenuPanel>
    );
  }

  return (
    <MenuPanel open={open}>
      {childMenu?.children
        ?.filter((r) => r.href)
        ?.filter((r) => AccountStorage.hasModule(r.code))
        .map((menu) => {
          return (
            <MyMenuItemLink
              to={menu.href!}
              key={menu.name}
              state={{ _scrollToTop: true }}
              primaryText={menu.name}
              leftIcon={menu.icon}
              dense={dense}
            />
          );
        })}
    </MenuPanel>
  );
};

export default Menu;
