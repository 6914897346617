import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { AmountCommand } from "./AmountCommand";
import { Field } from "../../utils/Field";
import React from "react";
import { UserWallet } from "../../model/UserWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import { ResourceType } from "../../components/core/MyResource";

const fields: MyField<UserWallet>[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.text({
    label: "币种",
    source: "coinSymbol",
  }),
  Field.array({
    label: "钱包地址",
    source: "address",
    transform: ([k, v]) => {
      return `${k} : ${v}`;
    },
  }),
  Field.text(
    {
      label: "余额",
      source: "balance",
    },
    {
      isFilter: false,
    }
  ),
  Field.text(
    {
      label: "锁定余额",
      source: "frozenBalance",
    },
    {
      isFilter: false,
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const UserWalletTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource: ResourceType = {
  commands: [<AmountCommand type={"Add"} />, <AmountCommand type={"Deduct"} />],
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{
          coinSymbol: "EURO",
        }}
      />
    );
  },
  // create: () => {
  //   return <MyCreate fields={fields} />;
  // },
  // edit: () => {
  //   return (
  //     <MyEdit
  //       transform={(data) => {
  //         if (data?.password.length === 0) {
  //           return omit(data, ["password"]);
  //         }
  //         return data;
  //       }}
  //       fields={fields}
  //     />
  //   );
  // },
  icon: <WalletIcon />,
};
export default resource;
