import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import {
  AgentStatisticsModel,
  DashboardJsonView,
} from "../model/DashboardJsonView";

export const DashboardApi = {
  getByAgentId: async (agentId: string): Promise<DashboardJsonView> => {
    let { data } = await fetchJson(
      `${API_URL}/dashboard/getByAgentId/${agentId}`,
      {
        method: "GET",
      }
    );
    return data;
  },
  getAgentByDateRange: async (dateRange: {
    startDate: string;
    endDate: string;
  }): Promise<AgentStatisticsModel[] | undefined> => {
    let { data } = await fetchJson(`${API_URL}/dashboard/getAgentByDateRange`, {
      method: "POST",
      body: new URLSearchParams(dateRange),
    });
    return data;
  },
};
