import {
  BaseCommand,
  BaseCommandProps,
} from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { Payments } from "@mui/icons-material";
import React from "react";
import { CommandProps } from "../../components/command/Command";
import { useConfirmForm } from "../../hook/ConfirmContext";
import {
  deduct,
  recharge,
} from "../../service/adminUserWallet/api/adminUserWalletService";
import { NumberInput, required, useNotify } from "react-admin";

export type Type = "Add" | "Deduct";

export interface AmountCommandProps extends CommandProps {
  type: Type;
}

AmountCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
} as BaseCommandProps;

export function AmountCommand(props: AmountCommandProps) {
  const { type, ...rest } = props;
  const record = useRecordContext(props);
  const notify = useNotify();
  const confirm = useConfirmForm();
  let label = type === "Add" ? "充值" : "扣款";
  return (
    <BaseCommand
      variant={"contained"}
      command={{
        label: label,
        icon: <Payments />,
        onHandle: async () => {
          const form = await confirm({
            title: label,
            form: (
              <>
                <NumberInput
                  label={"请输入金额"}
                  isRequired
                  validate={[required()]}
                  source={"amount"}
                  defaultValue={1}
                />
              </>
            ),
          });
          //
          if (form) {
            if (type === "Add") {
              await recharge({
                userId: record.userId,
                type: record.type,
                amount: form.amount,
                coinSymbol: record.coinSymbol,
              });
            } else {
              await deduct({
                userId: record.userId,
                amount: form.amount,
                type: record.type,
                coinSymbol: record.coinSymbol,
              });
            }
            notify("操作成功");
          }
          return true;
        },
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
