import { UserWalletType } from "./UserWallet";

interface ErrorInfo {
  error: string;
  errorDate: string;
}

export const WalletOpTypeChoices = [
  { id: "ADMIN_SET", name: "后台设置" },
  { id: "COMMISSION_RECHARGE", name: "佣金" },
  { id: "ADMIN_RECHARGE", name: "后台充值" },
  { id: "ADMIN_DEDUCT", name: "后台扣除" },
  { id: "NFT_OUT", name: "nft支出" },
  { id: "NFT_IN", name: "nft收入" },
  { id: "FUND_OUT", name: "理财划出" },
  { id: "FUND_IN", name: "理财划入" },
  { id: "RECHARGE", name: "充值" },
  { id: "WITHDRAW", name: "提现" },
  { id: "TRANSFER_IN", name: "划入" },
  { id: "TRANSFER_OUT", name: "划出" },
  { id: "TRADE_IN", name: "交易买入" },
  { id: "TRADE_OUT", name: "交易卖出" },
  { id: "MARGIN_OUT", name: "平仓亏损" },
  { id: "MARGIN_IN", name: "平仓收益" },
  { id: "MARGIN_REVERSAL", name: "冲正" },
  { id: "TRADE_FREEZE", name: "交易冻结" },
  { id: "TRADE_THAW", name: "交易解冻" },
  { id: "TRADE_FEE", name: "扣除手续费" },
];

export enum WalletOpType {
  /**
   * 后台充值
   */
  ADMIN_RECHARGE = "ADMIN_RECHARGE",
  COMMISSION_RECHARGE = "COMMISSION_RECHARGE",

  /**
   *
   */
  ADMIN_DEDUCT = "ADMIN_DEDUCT",

  /**
   *
   */
  NFT_OUT = "NFT_OUT",

  /**
   *
   */
  NFT_IN = "NFT_IN",

  /**
   * 理财划出
   */
  FUND_OUT = "FUND_OUT",

  /**
   * 理财划入
   */
  FUND_IN = "FUND_IN",

  /**
   * 充值
   */
  RECHARGE = "RECHARGE",

  /**
   * 提现
   */
  WITHDRAW = "WITHDRAW",

  /**
   * 划入
   */
  TRANSFER_IN = "TRANSFER_IN",

  /**
   * 划出
   */
  TRANSFER_OUT = "TRANSFER_OUT",

  /**
   * 交易买入
   */
  TRADE_IN = "TRADE_IN",

  /**
   * 交易卖出
   */
  TRADE_OUT = "TRADE_OUT",

  /**
   * 平仓亏损
   */
  MARGIN_OUT = "MARGIN_OUT",

  /**
   * 平仓收益
   */
  MARGIN_IN = "MARGIN_IN",

  /**
   * 冲正
   */
  MARGIN_REVERSAL = "MARGIN_REVERSAL",

  /**
   * 交易冻结
   */
  TRADE_FREEZE = "TRADE_FREEZE",

  /**
   * 交易解冻
   */
  TRADE_THAW = "TRADE_THAW",

  /**
   * 扣除手续费
   */
  TRADE_FEE = "TRADE_FEE",
}

export const LogStatusTypeChoices = [
  { id: "INIT", name: "初始化" },
  { id: "NORMAL", name: "NORMAL" },
  { id: "ERROR", name: "ERROR" },
];

export enum LogStatusType {
  INIT = "INIT",
  NORMAL = "NORMAL",
  ERROR = "ERROR",
}

export interface UserWalletLogModel {
  id: string;

  logStatus: LogStatusType;

  userWalletId: string;
  userId: string;

  type: UserWalletType;

  coinSymbol: string;

  opType: WalletOpType;

  opOrderId?: string;

  opAccountId?: string;

  opAccountType?: string;

  opAccountClientUa?: string;

  opAccountClientIp?: string;

  /**
   * 金额
   */
  amount: string;

  /**
   * 手续费
   */
  fee: string;

  feeUnit: string;

  /**
   * 交易后余额
   */
  historyBalance: string;

  /**
   * 交易后冻结余额
   */
  historyFrozenBalance: string;

  error?: string;

  errorDate?: string;

  errors?: ErrorInfo[];

  createdDate: string;
  updateDate: string;
}
