export enum NftGoodsStatus {
  UP = "UP",
  DOWN = "DOWN",
}

export const NftGoodsStatusChoices = [
  { id: NftGoodsStatus.UP, name: "上架" },
  { id: NftGoodsStatus.DOWN, name: "下架" },
];

export interface GoodsModel {
  id: number;

  status: NftGoodsStatus;

  category: GoodsCategoryModel;

  price: string;

  logo: string;

  title: string;

  content: string;

  createdDate: string;
}

export interface GoodsCategoryModel {
  id: number;
  logo: string;
  vipLevel: number;

  title: string;
  content: string;

  createdDate: string;
}
