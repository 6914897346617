import React, { FormEvent } from "react";
import { useCreateSuggestionContext } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { stringToNumber, toStringNotSymbol } from "../../utils/amountUtils";
import unionBy from "lodash/unionBy";
import { AttachMoney } from "@mui/icons-material";

export const PayFaceValues = [
  { id: "100", name: "100" },
  { id: "200", name: "200" },
];

export function MergeFaceValue(values: string[]) {
  // 将value数组转换为具有相同结构的对象数组
  const valuesAsObjects = values.map((value) => {
    let v = stringToNumber(value, 1);
    return {
      id: toStringNotSymbol(v),
      name: value,
    };
  });
  return unionBy(PayFaceValues, valuesAsObjects, "id");
}

export type CreateFaceValueProps = {
  faceValues: typeof PayFaceValues;
};

export const CreateFaceValue = ({ faceValues }: CreateFaceValueProps) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const newOption = { id: value, name: value };
    faceValues.push(newOption);
    setValue("");
    onCreate(newOption);
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="面值"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AttachMoney />
                </InputAdornment>
              ),
            }}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">添加</Button>
          <Button onClick={onCancel}>取消</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
