export enum SiteResourceType {
  HTML = "HTML",
  IMAGE = "IMAGE",
  IMAGES = "IMAGES",

  VIDEOS = "VIDEOS",
}

export const SiteResourceTypeChoices = [
  { id: SiteResourceType.HTML, name: "网页" },
  { id: SiteResourceType.IMAGE, name: "图片" },
  { id: SiteResourceType.IMAGES, name: "图片集" },
  { id: SiteResourceType.VIDEOS, name: "视频集" },
];
