import Users from "./pages/User";
import UserWallets from "./pages/user/UserWallet";
import GoodsCategory from "./pages/goods/GoodsCategory";
import Goods from "./pages/goods/Goods";
import PayTasks from "./pages/task/PayTasks";
import UserLevelSetting from "./pages/user/UserLevelSetting";
import { MenuItem } from "./model/menu";
import UserBankCard from "./pages/user/UserBankCard";
import SiteNotification from "./pages/content/SiteNotification";
import Docs from "./pages/content/Doc";

export const menuItems: MenuItem[] = [
  {
    name: "会员管理",
    code: "userManager",
    icon: Users.icon,
    children: [
      {
        name: "会员列表",
        icon: Users.icon,
        code: "users",
        href: "/users",
      },
      {
        name: "会员层级",
        icon: Users.icon,
        code: "userByGroup",
        href: "/userByGroup",
      },
      {
        name: "账务记录",
        code: "userWalletLog",
      },
      {
        name: "会员等级",
        icon: UserLevelSetting.icon,
        code: "userLevelSetting",
        href: "/userLevelSetting",
      },
      {
        name: "钱包管理",
        icon: UserWallets.icon,
        code: "userWallets",
        href: "/userWallets",
      },
      {
        name: "连单",
        icon: PayTasks.icon,
        code: "payTasks",
        href: "/payTasks",
      },
      {
        name: "银行卡",
        icon: UserBankCard.icon,
        code: "userBankCard",
        href: "/userBankCard",
      },
    ],
  },
  {
    name: "交易",
    icon: Goods.icon,
    code: "exchange",
    children: [
      {
        name: "订单列表",
        icon: GoodsCategory.icon,
        code: "order",
        href: "/order",
      },
      {
        name: "提现列表",
        icon: GoodsCategory.icon,
        code: "withdrawRecord",
        href: "/withdrawRecord",
      },
      {
        name: "风控信息",
        icon: GoodsCategory.icon,
        code: "userRisk",
        href: "/userRisk",
      },
    ],
  },
  {
    name: "内容管理",
    icon: Docs.icon,
    code: "contentManager",
    children: [
      {
        name: "通知管理",
        icon: SiteNotification.icon,
        code: "siteNotification",
        href: "/siteNotification",
      },
    ],
  },
];
