import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { UserWallet } from "../model/UserWallet";

interface UpdateBalanceForm extends Record<string, string> {
  userId: string;
  balance: string;
  frozenBalance: string;
  remark: string;
}

export const UserWalletManagerApi = {
  findByUserId: async (userId: string): Promise<UserWallet> => {
    const { data } = await fetchJson(
      `${API_URL}/userWallets/findByUserId/${userId}`,
      {
        method: "GET",
      }
    );
    return data;
  },
  updateBalance: async (form: UpdateBalanceForm): Promise<UserWallet> => {
    const { data } = await fetchJson(`${API_URL}/userWallets/updateBalance`, {
      method: "POST",
      body: new URLSearchParams(form),
    });
    return data;
  },
};
