import { Button } from "@mui/material";
import ConfirmDialog from "../core/ConfirmDialog";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { WithdrawManagerApi } from "../../api/WithdrawManagerApi";

function RejectBtn({ record }: { record: any }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await WithdrawManagerApi.reject(record.id);
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"warning"}
        onClick={handleClickOpen}
      >
        驳回
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"驳回操作"}
        description={"确定驳回吗?"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default RejectBtn;
