import {
  Box,
  Button as MuiButton,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { FormEvent, useEffect, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { UserBankCardManagerApi } from "../../../api/UserBankCardManagerApi";
import { UserBankCardForm } from "../../../api/UserBankCardManagerApi";

function EditBankCardBtn({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [localUserId, setLocalUserId] = useState(userId);

  const defaultEntity = {
    userId: localUserId,
  } as UserBankCardForm;

  const [formData, setFormData] = useState<UserBankCardForm>(defaultEntity);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  const handleClickOpen = async () => {
    const data = await UserBankCardManagerApi.findByUserId(localUserId);
    if (data) {
      setFormData(data as UserBankCardForm);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    await UserBankCardManagerApi.update(formData);
    notify("操作成功");
    handleClose();
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        银行卡信息
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">银行卡信息</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={onSubmit}>
              <Stack direction={"column"} spacing={2}>
                <TextInput
                  name="symbol"
                  disabled={true}
                  label={"币种"}
                  defaultValue={formData.symbol}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      symbol: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="recipientCountry"
                  label={"收款国家"}
                  defaultValue={formData.recipientCountry}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      recipientCountry: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="accountName"
                  label={"账户名"}
                  defaultValue={formData.accountName}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      accountName: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="accountNumber"
                  label={"账户号 / IBAN"}
                  defaultValue={formData.accountNumber}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      accountNumber: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="accountCode"
                  label={"SWIFT/BIC"}
                  defaultValue={formData.accountCode}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      accountCode: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="cashierInformation"
                  label={"收款方信息"}
                  defaultValue={formData.cashierInformation}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      cashierInformation: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="enterpriseName"
                  label={"企业名称"}
                  defaultValue={formData.enterpriseName}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      enterpriseName: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="firstName"
                  label={"名"}
                  defaultValue={formData.firstName}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));
                  }}
                />
                <TextInput
                  name="lastName"
                  label={"姓"}
                  defaultValue={formData.lastName}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }));
                  }}
                />
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

interface TextInputProps {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

function TextInput({
  name,
  label,
  defaultValue,
  onChange,
  disabled = false,
}: TextInputProps) {
  return (
    <TextField
      disabled={disabled}
      required
      fullWidth
      name={name}
      label={label}
      variant="standard"
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
}

export default EditBankCardBtn;
