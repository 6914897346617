import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import UserByGroupList from "../components/userByGroup/UserByGroupList";

const resource = {
  list: () => {
    return <UserByGroupList />;
  },
  icon: <PermContactCalendarIcon />,
};

export default resource;
