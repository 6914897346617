import {
  DeleteButton,
  EditButton,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Assignment, Help } from "@mui/icons-material";
import {
  AmountType,
  AmountTypeValueEnum,
  CommissionType,
  CommissionTypeValueEnum,
} from "../../model/PayTasks";
import { Tooltip, Typography } from "@mui/material";
import { FormDataConsumer } from "ra-core";
import { AmountInput } from "../../components/AmountInput";
import { PercentInput } from "../../components/PercentInput";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "分组名称",
    source: "name",
    isRequired: true,
    isFilter: false,
  }),
  Field.editAmount({
    label: "最低金额",
    source: "minAmount",
  }),
  Field.text({
    label: "订单数量",
    source: "orderCount",
    defaultValue: 1,
  }),
  Field.editAmount({
    label: "触发VIP级别",
    source: "upLevel",
  }),
  Field.ref({
    label: "触发连单",
    source: "nextPayTasksId",
    isRequired: false,
    isList: true,
    isFilter: false,
    reference: "payTasks",
    referenceLabel: "name",
    originSource: "id",
  }),
  Field.iterator({
    label: "规则数量",
    source: "tasks",
    children: (
      <SimpleFormIterator
        fullWidth
        inline
        getItemLabel={(index) => `第${index + 1}单`}
      >
        <SelectInput
          source="commissionType"
          label={"佣金类型"}
          choices={CommissionTypeValueEnum}
          defaultValue={CommissionType.RATIO}
          isRequired
          helperText={
            <>
              <Tooltip
                arrow
                title={
                  <>
                    <Typography variant="caption" display="block">
                      固定值：不管订单金额多少钱，都按照这个金额发放佣金
                    </Typography>
                    <Typography variant="caption" display="block">
                      百分比：按照订单金额百分比发放佣金
                    </Typography>
                  </>
                }
              >
                <Typography variant="caption">
                  佣金类型 <Help style={{ fontSize: 14 }} />
                </Typography>
              </Tooltip>
            </>
          }
        />
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            console.log("formData", formData, scopedFormData, rest);
            let commissionType = scopedFormData?.commissionType;
            if (commissionType === CommissionType.FIXED) {
              return (
                <AmountInput
                  label={"佣金"}
                  required
                  source={getSource("commissionValue")}
                  helperText={"固定值"}
                />
              );
            } else if (commissionType === CommissionType.RATIO) {
              return (
                <PercentInput
                  label={"佣金"}
                  required
                  source={getSource("commissionValue")}
                  helperText={"百分比"}
                />
              );
            }
          }}
        </FormDataConsumer>
        <SelectInput
          source="amountType"
          choices={AmountTypeValueEnum}
          label={"做单类型"}
          defaultValue={AmountType.AMOUNT}
          isRequired
          helperText={
            <>
              <Tooltip
                arrow
                title={
                  <>
                    <Typography variant="caption" display="block">
                      随机模式：订单金额随机。
                    </Typography>
                    <Typography variant="caption" display="block">
                      固定模式：用户正常做单，不会加价。
                    </Typography>
                    <Typography variant="caption" display="block">
                      比例模式：根据用户余额 + 余额的百分比
                    </Typography>
                    <Typography variant="caption" display="block">
                      补金模式：如用户余额是500 + 补金300 = 订单金额 800
                    </Typography>
                  </>
                }
              >
                <Typography variant="caption">
                  做单类型 <Help style={{ fontSize: 14 }} />
                </Typography>
              </Tooltip>
            </>
          }
        />
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            let commissionType = scopedFormData?.amountType;
            if (commissionType === AmountType.AMOUNT) {
              return (
                <AmountInput
                  required
                  label={"价格"}
                  source={getSource("amount")}
                  helperText={"固定值"}
                />
              );
            } else if (commissionType === AmountType.RATIO) {
              return (
                <PercentInput
                  required
                  label={"价格"}
                  source={getSource("amount")}
                  helperText={"根据用户余额来进行百分比加价"}
                />
              );
            } else if (commissionType === AmountType.SUPPLEMENT) {
              return (
                <>
                  <AmountInput
                    required
                    label={"价格下限"}
                    source={getSource("amount")}
                    helperText={"价格下限"}
                  />
                  <AmountInput
                    required
                    label={"价格上限"}
                    source={getSource("amountLimit")}
                    helperText={"价格上限"}
                  />
                </>
              );
            } else if (commissionType === AmountType.RANDOM) {
              return (
                <>
                  <AmountInput
                    required
                    label={"价格下限"}
                    source={getSource("amount")}
                    helperText={"价格下限"}
                  />
                  <AmountInput
                    required
                    label={"价格上限"}
                    source={getSource("amountLimit")}
                    helperText={"余额上限"}
                  />
                </>
              );
            }
          }}
        </FormDataConsumer>
        <NumberInput
          source={"intervalSec"}
          label={"抢单间隔"}
          defaultValue={5}
          required
        />
      </SimpleFormIterator>
    ),
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <Assignment />,
};

export default resource;
