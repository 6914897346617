import React, { useMemo } from "react";
import { EditButton, useAuthState } from "react-admin";
import { useResourceContext } from "ra-core";
import { AccountStorage } from "../../AccountStorage";
import { Button } from "@mui/material";

export interface MyEditButtonProps {}

export const MyEditButton: React.FC<MyEditButtonProps> = (
  props: MyEditButtonProps
) => {
  const resource = useResourceContext(props);
  const { isLoading } = useAuthState();
  const permission = useMemo(() => {
    return AccountStorage.hasModule(resource + ".update");
  }, [isLoading]);

  if (permission) {
    return <EditButton />;
  }
  return <Button />;
};
