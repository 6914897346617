import { Button } from "@mui/material";
import { ReferenceManyField } from "react-admin";
import { UserWalletLog } from "../../pages/user/UserWalletLog";
import React, { useState } from "react";
import ConfirmDialog from "../core/ConfirmDialog";

function OrderFlowerBtn({ record }: { record: any }) {
  console.log("recordContext: ", record);

  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"info"}
        onClick={handleClickOpen}
      >
        资金流水
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"资金流水"}
        description={
          <ReferenceManyField
            reference="userWalletLog"
            target="orderId"
            source={record.id}
            label={false}
          >
            <UserWalletLog />
          </ReferenceManyField>
        }
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default OrderFlowerBtn;
