import { Button, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import UserPrickNeedle from "../../../pages/user/UserPrickNeedle";

function AddPrickNeedleBtn({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [localUserId, setLocalUserId] = useState(userId);

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = (data: any, variables: any) => {
    handleClose();
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        打针
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>添加打针</DialogTitle>
        {UserPrickNeedle.create(
          {
            userId: localUserId,
            prickDate: new Date(),
            commissionType: "RATIO",
          },
          onSuccess
        )}
      </Dialog>
    </>
  );
}

export default AddPrickNeedleBtn;
