import * as React from "react";
import { isValidElement, ReactElement, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { isValidElementType } from "react-is";
import { ResourceContextProvider, ResourceProps } from "ra-core";
import { Content } from "../../layout/Content";
import { useAuthState } from "react-admin";
import { AccountStorage } from "../../AccountStorage";
import { defaultCommandProps } from "../../model/command";
import { CommandProps } from "../command/Command";
import { CreateCommand } from "../command/CreateCommand";
import { EditCommand } from "../command/EditCommand";
import { DeleteCommand } from "../command/DeleteCommand";
import { ShowCommand } from "../command/ShowCommand";
import {
  ResourceConfig,
  ResourceConfigProvider,
} from "../../hook/ResourceConfigContext";

export interface MyResourceProps extends Omit<ResourceProps, "icon"> {
  commands?: React.ReactElement<CommandProps>[];
  icon?: ReactElement;
  hasDelete?: boolean;
}

export type ResourceType = Partial<MyResourceProps>;

MyResource.defaultProps = {
  commands: [
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand />,
    <DeleteCommand />,
  ],
  hasDelete: true,
};

export function MyResource(props: MyResourceProps) {
  const { create, edit, list, name, show } = props;

  const { isLoading } = useAuthState();

  const permission = useMemo(() => {
    return {
      create: AccountStorage.hasModule(name + ".create"),
      delete: AccountStorage.hasModule(name + ".delete"),
      update: AccountStorage.hasModule(name + ".update"),
      show: AccountStorage.hasModule(name + ".show"),
    };
  }, [isLoading, name]);

  const resourceConfig = useMemo(() => {
    return {
      commands: props.commands?.map((command) => ({
        ...defaultCommandProps,
        ...command,
      })),
      hasDelete: props.hasDelete,
    } as ResourceConfig;
  }, [props]);

  return (
    <ResourceContextProvider value={name}>
      <ResourceConfigProvider value={resourceConfig}>
        <Routes>
          {create && permission.create && (
            <Route path="create/*" element={getElement(create)} />
          )}
          {show && permission.show && (
            <Route path=":id/show/*" element={getElement(show)} />
          )}
          {edit && permission.update && (
            <Route path=":id/*" element={getElement(edit)} />
          )}
          {list && permission.show && (
            <Route path="/*" element={getElement(list)} />
          )}
          {props.children}
        </Routes>
      </ResourceConfigProvider>
    </ResourceContextProvider>
  );
}

const getElement = (
  ElementOrComponent: React.ComponentType<any> | React.ReactElement
) => {
  if (isValidElement(ElementOrComponent)) {
    return <Content>{ElementOrComponent}</Content>;
  }
  if (isValidElementType(ElementOrComponent)) {
    return (
      <Content>
        <ElementOrComponent />
      </Content>
    );
  }
  return null;
};

MyResource.raName = "Resource";

MyResource.registerResource = ({
  create,
  edit,
  icon,
  list,
  name,
  options,
  show,
  recordRepresentation,
  hasCreate,
  hasEdit,
  hasShow,
}: ResourceProps) => ({
  name,
  options,
  hasList: !!list,
  hasCreate: !!create || !!hasCreate,
  hasEdit: !!edit || !!hasEdit,
  hasShow: !!show || !!hasShow,
  icon,
  recordRepresentation,
});
