import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import ConfirmDialog from "../../core/ConfirmDialog";
import { UserManagerApi } from "../../../api/UserManagerApi";

function EditBankCardBtn({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [localUserId, setLocalUserId] = useState(userId);
  const notify = useNotify();

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await UserManagerApi.updateState(localUserId, "DISABLE");
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        禁用
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"禁用确认"}
        description={"确认禁用?"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default EditBankCardBtn;
