import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin } from "../model/Admin";

export interface UserModel {
  id: string;
  uid: string;
  name: string;
  phone: string;
  parentUserId?: string;
}

export const UserManagerApi = {
  unlock: async (id: string): Promise<Admin> => {
    const { data } = await fetchJson(`${API_URL}/users/unlock/${id}`, {
      method: "POST",
    });
    return data;
  },
  updateState: async (id: string, type: string): Promise<Admin> => {
    const { data } = await fetchJson(
      `${API_URL}/users/updateState/${id}/${type}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  handDeduct: async (userId: string, amount: number): Promise<Admin> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handDeduct`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
  handPay: async (userId: string, amount: number): Promise<Admin> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handPay`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
  getAllAgent: async (): Promise<UserModel[] | undefined> => {
    const { data } = await fetchJson(`${API_URL}/users/getAllAgent`, {
      method: "GET",
    });
    return data;
  },
};
