import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core";

export interface ImageMyOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    myImage: {
      /**
       * Add an image
       */
      setMyImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        width?: string;
        borderRadius?: string;
      }) => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const MyImage = Node.create<ImageMyOptions>({
  name: "image",

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: "100%",
        parseHTML: (element) => {
          return element.style.width;
        },
        renderHTML: (attributes) => {
          return {
            style: `width: ${attributes.width}`,
          };
        },
      },
      borderRadius: {
        default: "0",
        parseHTML: (element) => {
          console.log(element.style);
          return element.style.borderRadius;
        },
        renderHTML: (attributes) => {
          return {
            style: `border-radius: ${attributes.borderRadius}`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64
          ? "img[src]"
          : 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { width, borderRadius, ...rest } = HTMLAttributes;
    let styles = [];
    if (width) {
      styles.push(`width:${width}`);
    }
    if (borderRadius) {
      styles.push(`border-radius:${borderRadius}`);
    }
    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, rest, {
        style: styles.join(";"),
      }),
    ];
  },

  addCommands() {
    return {
      setMyImage: (options) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options,
        });
      },
    };
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title, width, borderRadius] = match;
          return { src, alt, title, width, borderRadius };
        },
      }),
    ];
  },
});
