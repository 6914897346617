import ObjectIdField from "../../components/ObjectIdField";
import { Box } from "@mui/material";
import * as React from "react";
import { MyField } from "../../components/types";
import { base, BaseArgs } from "./base";

export interface ObjectIdArgs extends Partial<BaseArgs> {}

const defaultArgs: BaseArgs = {
  label: "ID",
  source: "id",
  isEdit: true,
  sortable: true,
  isCreate: false,
  isUpdate: false,
  isFilter: false,
  isList: true,
};

export function objectId(args: ObjectIdArgs = {}, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      source: "id",
      component: ObjectIdField,
      editComponent: Box,
      editProps: {
        children: (
          <>
            ID:
            <ObjectIdField fullWidth />
          </>
        ),
      },
    },
    field
  );
}
