// @ts-ignore
import React, { useEffect, useState } from "react";
import StatisticsCard from "./StatisticsCard";
import AgentByGroup from "./AgentByGroup";

export const MyDashboard1 = () => {
  return (
    <>
      <StatisticsCard />
      <AgentByGroup />
    </>
  );
};
