import React from "react";
import { AutocompleteArrayInput, required } from "react-admin";
import get from "lodash/get";
import { CreateFaceValue, MergeFaceValue } from "./CreateFaceValue";
import { useRecordContext } from "ra-core";
import { toString } from "../../utils/amountUtils";
import { MyRichTextInput } from "../../components/MyRichTextInput";
import { MyTextInput } from "../../components/MyTextInput";
import { AmountInput } from "../../components/AmountInput";

export const PaySetting: React.FC = (props) => {
  const record = useRecordContext();

  let mergeFaceValue = MergeFaceValue(get(record, "setting.payFaceValue", []));
  return (
    <>
      <AutocompleteArrayInput
        validate={[required()]}
        label={`支付面值`}
        optionText={(r) => {
          return toString(r.id);
        }}
        source={"setting.payFaceValue"}
        choices={mergeFaceValue}
        create={<CreateFaceValue faceValues={mergeFaceValue} />}
        createItemLabel={"添加面值"}
      />
      <MyTextInput
        label={"订单超时时间(秒)"}
        source={"setting.orderInvalidSec"}
        type={"number"}
      />
      <AmountInput label={"注册默认金额"} source={"setting.defaultBalance"} />
      <MyRichTextInput label={"充值说明"} source={"setting.instructions"} />
    </>
  );
};
