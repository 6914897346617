import { MyField } from "../../components/types";
import { FunctionField, TextInput } from "react-admin";
import { base, BaseArgs } from "./base";

export interface FunctionArgs extends BaseArgs {
  render: (record?: any, source?: string) => any;
}

const defaultArgs: Partial<FunctionArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function functionField(args: FunctionArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      editComponent: TextInput,
      props: {
        render: args.render,
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
