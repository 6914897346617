import React from "react";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyImageInput } from "../../components/MyImageInput";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from "react-admin";
import {
  CreateCountryCodeValue,
  MergeCountryCodeValue,
} from "./CreateCountryCodeValue";
import { useRecordContext } from "ra-core";
import get from "lodash/get";

export const SiteSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "symbol");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };

  const record = useRecordContext();
  let mergeFaceValue = MergeCountryCodeValue(
    get(record, "setting.countryCodeList", [])
  );

  return (
    <>
      <MyImageInput label={"网站logo"} source={"setting.logoUrl"} />
      <MyImageInput
        label={"网站logoFavicon"}
        source={"setting.logoFaviconUrl"}
      />
      <MyImageInput label={"网站logoTitle"} source={"setting.logoTitleUrl"} />
      <MyImageInput label={"默认用户头像"} source={"setting.defaultUserHead"} />
      <TextInput label="网站名称" source="setting.name" />
      <MyLocalesValueInput label={"网站标题"} source={"setting.title"} />
      <MyLocalesValueInput label={"网站介绍"} source={"setting.description"} />
      <ReferenceInput
        label={"默认币种"}
        source={"setting.coinSymbol"}
        reference={"coins"}
      >
        <AutocompleteInput
          validate={[required()]}
          label={`默认币种`}
          optionText={optionText}
          name={"setting.coinSymbol"}
        />
      </ReferenceInput>
      <AutocompleteArrayInput
        validate={[required()]}
        label={`区号`}
        optionText={(r) => {
          return r.id;
        }}
        source={"setting.countryCodeList"}
        choices={mergeFaceValue}
        create={<CreateCountryCodeValue faceValues={mergeFaceValue} />}
        createItemLabel={"区号"}
      />
    </>
  );
};
