import { useCallback, useState } from "react";
import { Result } from "../utils/request";
import { AccountStorage } from "../AccountStorage";
import { useNotify } from "react-admin";

interface UploadCallbacks<T> {
  onError?: (error: Error) => void;
  onSuccess?: (result: Result<T | undefined>) => void;
}

export function useUpload<T>(callbacks: UploadCallbacks<T> = {}) {
  const [progress, setProgress] = useState<number>(0);
  const notify = useNotify();

  const uploadFile = useCallback(
    async (file: File, url: string) => {
      // 创建一个 FormData 对象，用于存储要上传的文件
      const formData = new FormData();
      formData.append("file", file);

      // 创建一个 XMLHttpRequest 对象和 AbortController 对象
      const xhr = new XMLHttpRequest();
      // 配置请求

      // 监听上传进度事件
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          console.log("progress: ", progress);
          setProgress(progress);
        }
      });

      // 监听请求完成事件
      xhr.addEventListener("load", () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          console.log("upload success: ", xhr.responseText);
          notify("上传完成");
          callbacks.onSuccess?.(JSON.parse(xhr.responseText));
        } else {
          const error = new Error(`error: ${xhr.status}`);
          callbacks.onError?.(error);
        }
        setProgress(100);
      });

      // 监听请求错误事件
      xhr.addEventListener("error", (e) => {
        const error = new Error(
          `upload error ${e.type}: ${e.loaded} bytes transferred\n`
        );
        callbacks.onError?.(error);
        setProgress(0);
      });

      let admin = AccountStorage.get();

      xhr.open("POST", url, true);
      if (admin && admin.token) {
        xhr.setRequestHeader("Authorization", admin.token);
      }
      xhr.send(formData);
      // 在组件卸载时取消请求
      return () => {
        xhr.abort();
      };
    },
    [callbacks]
  );

  return { progress, uploadFile };
}
