import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import ConfirmDialog from "../../core/ConfirmDialog";
import { UserLevelRecordApi } from "../../../api/UserLevelRecordApi";

function EditBankCardBtn({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [localUserId, setLocalUserId] = useState(userId);
  const notify = useNotify();

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await UserLevelRecordApi.startMakeOrder(localUserId);
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        做单
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"做单确认"}
        description={
          <>
            <Typography>确认开始做单?</Typography>
            <Typography color={"error"}>
              请考虑清楚在确认，该操作会更新到新的连单， 当前连单未完成也会修改！
            </Typography>
          </>
        }
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default EditBankCardBtn;
