import { EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field, isNotRequired } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { BooleanTypeChoices, StatusTypeChoices } from "../../model/Core";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";

const fields: MyField<API.Coin>[] = [
  Field.text({
    label: "id",
    source: "id",
    isList: false,
    isCreate: false,
    isFilter: false,
    isEdit: false,
    isUpdate: false,
  }),
  Field.text({
    label: "代号",
    source: "symbol",
    isCreate: true,
    isEdit: true,
    isUpdate: false,
  }),
  Field.text({
    label: "单位",
    source: "unit",
  }),
  Field.maxText({
    label: "图标地址",
    source: "iconUrl",
  }),
  Field.editNumber({
    label: "币种精度",
    source: "decimals",
  }),
  Field.editNumber({
    label: "确认次数",
    source: "confirms",
  }),
  Field.editNumber({
    label: "最低充值",
    source: "minRecharge",
  }),
  Field.editNumber({
    label: "最小提现数量",
    source: "minWithdraw",
  }),
  Field.editNumber({
    label: "最大提现数量",
    source: "maxWithdraw",
  }),
  Field.editNumber({
    label: "最低手续费",
    source: "minWithdrawFee",
  }),
  Field.editNumber({
    label: "最高手续费",
    source: "maxWithdrawFee",
  }),
  Field.editSelect({
    label: "是否可以充值",
    source: "isRecharge",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否可以提现",
    source: "isWithdraw",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
  }),
  Field.maxText(
    {
      label: "充值备注，eos用",
      source: "rechargeIvu",
    },
    {
      isList: false,
      ...isNotRequired,
    }
  ),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[<EditButton />]} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CurrencyBitcoinIcon />,
};

export default resource;
