import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import CategoryIcon from "@mui/icons-material/Category";
import { StatusTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.localesValue({
    label: "名称",
    source: "title",
    fullWidth: true,
    isRequired: true,
    isFilter: false,
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
  }),
  Field.img({
    label: "分类logo",
    isRequired: true,
    source: "logo",
  }),
  Field.editNumber({
    label: "绑定会员等级",
    source: "vipLevel",
  }),
  Field.richText({
    label: "介绍",
    source: "content",
    isRequired: true,
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CategoryIcon />,
};

export default resource;
