import { LoginRecord } from "./model/Admin";

const STORAGE_KEY = "account";

export const AccountStorage = new (class AccountStorage {
  private _admin: LoginRecord | undefined = undefined;
  private _token: string | undefined = undefined;
  private _modules: Set<string> = new Set();

  set(admin: LoginRecord, modules?: Set<string>) {
    this._admin = admin;
    this._token = admin.token;
    if (modules) {
      this._modules = modules;
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(admin));
  }

  getModules(): Set<string> {
    return this._modules;
  }

  hasModule(code: string): boolean {
    // return this._modules.has(code);
    return true;
  }

  get(): LoginRecord | undefined {
    return this._admin;
  }

  clear(): void {
    localStorage.removeItem(STORAGE_KEY);
    this._admin = undefined;
    this._token = undefined;
  }

  loadStorage(): LoginRecord | undefined {
    const adminJson = localStorage.getItem(STORAGE_KEY);
    if (adminJson != null) {
      let admin = JSON.parse(adminJson);
      this.set(admin);
      return admin;
    } else {
      return undefined;
    }
  }

  // get: async () => {
  //   const adminJson = localStorage.getItem("admin");
  //   if (adminJson != null) {
  //     const admin = JSON.parse(adminJson);
  //   }
  // }
})();

AccountStorage.loadStorage();
