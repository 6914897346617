import { Pagination, ReferenceManyField, TabbedShowLayout } from "react-admin";
import { MyShowFields } from "../MyShowFields";
import { UserWalletTable } from "../../pages/user/UserWallet";
import { UserWalletLog } from "../../pages/user/UserWalletLog";
import { MyShow } from "../core/MyShow";
import React, { useEffect } from "react";
import { MyField } from "../types";
import { Card, Divider } from "@mui/material";
import { UserLevelRecordCom } from "../../pages/user/UserLevelRecord";
import EditUserLevelBtn from "./component/EditUserLevelBtn";
import AddBonusBtn from "./component/AddBonusBtn";
import { UserPrickNeedleCom } from "../../pages/user/UserPrickNeedle";
import AddPrickNeedleBtn from "./component/AddPrickNeedleBtn";
import EditUserBalanceBtn from "./component/EditUserBalanceBtn";
import EditBankCardBtn from "./component/EditBankCardBtn";
import EditUserAddressBtn from "./component/EditUserAddressBtn";
import EditUserStateBtn from "./component/EditUserStateBtn";
import MakeOrderBtn from "./component/MakeOrderBtn";

function UserShowCom({ fields }: { fields: MyField[] }) {
  const [userId, setUserId] = React.useState("");

  useEffect(() => {
    const hash = window.location.hash;
    const segments = hash.split("/");
    setUserId(segments[2]);
  }, []);

  return (
    <MyShow title={`用户`}>
      <MyShowFields fields={fields} />
      <Divider />
      <Card sx={{ display: "flex", columnGap: "4px", padding: 2 }}>
        {/* 等级 */}
        <EditUserLevelBtn userId={userId} />
        {/* 彩金 */}
        <AddBonusBtn userId={userId} />
        {/* 打针 */}
        <AddPrickNeedleBtn userId={userId} />
        {/* 编辑余额 */}
        {/*<EditUserBalanceBtn userId={userId} />*/}
        {/* 做单 */}
        <MakeOrderBtn userId={userId} />
        {/*<Button size="small" variant="contained">*/}
        {/*  登录*/}
        {/*</Button>*/}
        {/* 银行卡信息 */}
        <EditBankCardBtn userId={userId} />
        {/* 收货地址 */}
        <EditUserAddressBtn userId={userId} />
        {/*<Button size="small" variant="contained">*/}
        {/*  查看团队*/}
        {/*</Button>*/}
        {/* 禁用 */}
        <EditUserStateBtn userId={userId} />
      </Card>

      <TabbedShowLayout value={1}>
        <TabbedShowLayout.Tab label="帐变">
          <ReferenceManyField
            reference="userWalletLog"
            target="userId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <UserWalletLog />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="等级" path={"userLevelRecord"}>
          <ReferenceManyField
            reference="userLevelRecord"
            target="userId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <UserLevelRecordCom />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="钱包" path={"userWallet"}>
          <ReferenceManyField
            reference="userWallets"
            target="userId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <UserWalletTable />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="打针记录" path={"userPrickNeedle"}>
          <ReferenceManyField
            reference="userPrickNeedle"
            target="userId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <UserPrickNeedleCom />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </MyShow>
  );
}

export default UserShowCom;
