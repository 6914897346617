import { Field } from "../Field";

export function userPayTaskField(
  originSourceName: string = "payTaskId",
  sourceName: string = "payTaskId"
) {
  return Field.ref({
    label: "连单",
    source: sourceName,
    reference: "payTasks",
    referenceLabel: "name",
    originSource: originSourceName,
    minWidth: 140,
  });
}
