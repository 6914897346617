import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Assessment } from "@mui/icons-material";
import { BooleanTypeChoices } from "../../model/Core";
import { ResourceType } from "../../components/core/MyResource";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";

const fields: MyField[] = [
  Field.text({
    label: "等级",
    source: "id",
    isCreate: true,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "等级名称",
    source: "name",
    isFilter: false,
    isRequired: true,
  }),
  Field.amount({
    label: "升级价格",
    source: "upAmount",
  }),
  Field.ref({
    label: "绑定连单",
    source: "taskId",
    isRequired: false,
    isList: true,
    isFilter: false,
    reference: "payTasks",
    referenceLabel: "name",
    originSource: "id",
  }),
  Field.amount({
    label: "余额限制",
    source: "taskMinAmount",
    helperText: "低于这个余额无法做单",
    defaultValue: "70",
  }),
  Field.percent({
    label: "佣金比例",
    source: "commissionRatio",
    helperText: "订单金额 x 佣金比例 = 得到佣金",
    defaultValue: "1",
  }),
  Field.editNumber({
    label: "提现次数",
    source: "payOutNumberLimit",
    helperText: "最多提现次数",
    defaultValue: 200,
  }),
  Field.editNumber({
    label: "提现日限制",
    source: "payOutDayOrderLimit",
    helperText: "提现需要完成几笔订单 / 天",
    defaultValue: 2,
  }),
  Field.percent({
    label: "提现手续费",
    source: "payOutFee",
    helperText: "提现手续费,百分比",
    defaultValue: "0",
  }),
  Field.amount({
    label: "单笔手续费",
    source: "payOutFeeAmountFixed",
    helperText: "提现单笔手续费",
    defaultValue: "0",
  }),
  Field.amount({
    source: "payOutAmountMin",
    label: "提现最小限制",
    defaultValue: "400",
  }),
  Field.amount({
    source: "payOutAmountMax",
    label: "提现最大限制",
    defaultValue: "1000000000.00",
  }),
  Field.editSelect({
    label: "是否能邀请",
    source: "allowInvite",
    choices: BooleanTypeChoices,
    defaultValue: true,
  }),
  Field.editSelect({
    label: "是否显示",
    source: "show",
    choices: BooleanTypeChoices,
    defaultValue: false,
  }),
  Field.editNumber({
    label: "有效天数",
    source: "validDays",
    defaultValue: 3650,
  }),
  Field.editNumber({
    label: "抢单间隔",
    source: "grabIntervalSec",
    helperText: "单位秒",
    defaultValue: 5,
  }),
  // Field.select({
  //   allowInvite
  // })
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <Assessment />,
};

export default resource;
