import { Button } from "@mui/material";
import ConfirmDialog from "../core/ConfirmDialog";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { WithdrawManagerApi } from "../../api/WithdrawManagerApi";

function MakePayBtn({ record }: { record: any }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await WithdrawManagerApi.makePay(record.id);
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"success"}
        onClick={handleClickOpen}
      >
        出款
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"出款操作"}
        description={"确定出款吗?"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default MakePayBtn;
