import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { AccountBalanceOutlined } from "@mui/icons-material";
import { PayChannelTypeChoices } from "../../model/payChannel";
import { StatusTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.select({
    label: "类型",
    source: "type",
    isFilter: true,
    choices: PayChannelTypeChoices,
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
  }),
  Field.localesValue({
    label: "显示名称",
    source: "name",
    fullWidth: true,
    isRequired: true,
    isFilter: false,
  }),
  Field.text({
    label: "通道编码",
    source: "payChannelCode",
    isFilter: false,
  }),
  Field.text({
    label: "商户号",
    source: "apiKey",
    fullWidth: true,
    isFilter: false,
  }),
  Field.text({
    label: "商户密钥",
    source: "apiSecret",
    fullWidth: true,
    isFilter: false,
    isList: false,
  }),
  Field.text({
    label: "接口地址",
    source: "apiUrl",
    fullWidth: true,
    isFilter: false,
    isList: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<EditButton />, <DeleteButton />]} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <AccountBalanceOutlined />,
};

export default resource;
