import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { DeleteButton } from "react-admin";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.userField(),
  Field.text({
    label: "用户名",
    source: "userName",
    isFilter: false,
  }),
  Field.text({
    label: "用户手机号",
    source: "userPhone",
    isFilter: false,
  }),
  Field.text({
    label: "风控编码",
    source: "riskCode",
    isFilter: false,
  }),
  Field.text({
    label: "风控条目",
    source: "riskName",
    isFilter: false,
  }),
  Field.text({
    label: "风控分数",
    source: "riskScore",
    isFilter: false,
  }),
  Field.text({
    label: "风控信息",
    source: "remarks",
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: (userId?: string) => {
    return (
      <MyList
        resource={"userRisk"}
        fields={fields}
        rowActions={[<DeleteButton />]}
        filterDefaultValues={{ userId: userId }}
      />
    );
  },
  icon: <PhotoLibraryIcon />,
};

export default resource;
