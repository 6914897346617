import { Button, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {
  UserLevelRecordApi,
  UserMakeOrderState,
} from "../../../api/UserLevelRecordApi";
import UserMakeOrderStatePage from "../../../pages/user/UserMakeOrderState";

function EditUserLevelBtn({ userId }: { userId: string }) {
  const [open, setOpen] = React.useState(false);
  const [currRecord, setCurrRecord] = React.useState<UserMakeOrderState>({});

  const handleClickOpen = async () => {
    const data = await UserLevelRecordApi.findByUserId(userId);
    console.log("request: userId -> ", userId, data);
    data && setCurrRecord(data);
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSuccess = () => {
    handleClose();
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        等级
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>编辑等级</DialogTitle>
        {open && (
          <Dialog
            open={open}
            fullWidth
            maxWidth={"xs"}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">编辑等级</DialogTitle>
            {UserMakeOrderStatePage.create(currRecord, onSuccess)}
          </Dialog>
        )}
      </Dialog>
    </>
  );
}

export default EditUserLevelBtn;
