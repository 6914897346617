import { EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import HubIcon from "@mui/icons-material/Hub";

const fields: MyField<API.Protocol>[] = [
  Field.text({
    label: "id",
    source: "id",
    isList: false,
    isCreate: false,
    isFilter: false,
    isEdit: false,
    isUpdate: false,
  }),
  Field.text({
    label: "代号",
    source: "symbol",
    isCreate: false,
    isEdit: true,
    isUpdate: false,
  }),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text(
    {
      label: "地址验证正则",
      source: "addressReg",
    },
    {
      editProps: {
        validate: [],
      },
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[<EditButton />]} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <HubIcon />,
};

export default resource;
