import React, { FormEvent } from "react";
import { useCreateSuggestionContext } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import unionBy from "lodash/unionBy";

export const CountryCodeValues = [
  { id: "44", name: "44" }, // 英国 (United Kingdom)
  { id: "355", name: "355" }, // 阿尔巴尼亚 (Albania)
  { id: "376", name: "376" }, // 安道尔 (Andorra)
  { id: "43", name: "43" }, // 奥地利 (Austria)
  { id: "375", name: "375" }, // 白俄罗斯 (Belarus)
  { id: "32", name: "32" }, // 比利时 (Belgium)
  { id: "387", name: "387" }, // 波黑 (Bosnia and Herzegovina)
  { id: "359", name: "359" }, // 保加利亚 (Bulgaria)
  { id: "385", name: "385" }, // 克罗地亚 (Croatia)
  { id: "357", name: "357" }, // 塞浦路斯 (Cyprus)
  { id: "420", name: "420" }, // 捷克 (Czech Republic)
  { id: "45", name: "45" }, // 丹麦 (Denmark)
  { id: "372", name: "372" }, // 爱沙尼亚 (Estonia)
  { id: "358", name: "358" }, // 芬兰 (Finland)
  { id: "33", name: "33" }, // 法国 (France)
  { id: "49", name: "49" }, // 德国 (Germany)
  { id: "30", name: "30" }, // 希腊 (Greece)
  { id: "36", name: "36" }, // 匈牙利 (Hungary)
  { id: "354", name: "354" }, // 冰岛 (Iceland)
  { id: "353", name: "353" }, // 爱尔兰 (Ireland)
  { id: "39", name: "39" }, // 意大利 (Italy)
  { id: "371", name: "371" }, // 拉脱维亚 (Latvia)
  { id: "370", name: "370" }, // 立陶宛 (Lithuania)
  { id: "352", name: "352" }, // 卢森堡 (Luxembourg)
  { id: "356", name: "356" }, // 马耳他 (Malta)
  { id: "373", name: "373" }, // 摩尔多瓦 (Moldova)
  { id: "377", name: "377" }, // 摩纳哥 (Monaco)
  { id: "382", name: "382" }, // 黑山 (Montenegro)
  { id: "31", name: "31" }, // 荷兰 (Netherlands)
  { id: "389", name: "389" }, // 北马其顿 (North Macedonia)
  { id: "47", name: "47" }, // 挪威 (Norway)
  { id: "48", name: "48" }, // 波兰 (Poland)
  { id: "351", name: "351" }, // 葡萄牙 (Portugal)
  { id: "40", name: "40" }, // 罗马尼亚 (Romania)
  { id: "7", name: "7" }, // 俄罗斯 (Russia)
  { id: "378", name: "378" }, // 圣马力诺 (San Marino)
  { id: "381", name: "381" }, // 塞尔维亚 (Serbia)
  { id: "421", name: "421" }, // 斯洛伐克 (Slovakia)
  { id: "386", name: "386" }, // 斯洛文尼亚 (Slovenia)
  { id: "34", name: "34" }, // 西班牙 (Spain)
  { id: "46", name: "46" }, // 瑞典 (Sweden)
  { id: "41", name: "41" }, // 瑞士 (Switzerland)
  { id: "380", name: "380" }, // 乌克兰 (Ukraine)
];

export function MergeCountryCodeValue(values: string[]) {
  // 将value数组转换为具有相同结构的对象数组
  const valuesAsObjects = values.map((value) => {
    return {
      id: value,
      name: value,
    };
  });
  return unionBy(CountryCodeValues, valuesAsObjects, "id");
}

export type CreateCountryCodeProps = {
  faceValues: typeof CountryCodeValues;
};

export const CreateCountryCodeValue = ({
  faceValues,
}: CreateCountryCodeProps) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const newOption = { id: value, name: value };
    faceValues.push(newOption);
    setValue("");
    onCreate(newOption);
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit} noValidate>
        <DialogContent>
          <TextField
            label="区号"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">添加</Button>
          <Button onClick={onCancel}>取消</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
