import React from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { UserByGroupApi, UserByGroupModel } from "../../../api/UserByGroupApi";

export interface UserTreeData extends TreeViewBaseItem, UserByGroupModel {}

export function useUserTreeData() {
  // 源list
  const [allIds, setAllIds] = React.useState<string[]>([]);

  // 当前使用数据
  const alTreeData = React.useRef<UserTreeData[]>([]);
  const [data, setData] = React.useState<UserTreeData[]>([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selectItems, setSelectItems] = React.useState<string[]>([]);

  // 搜索内容
  const [search, setSearch] = React.useState<string>("");

  const searchTimeout = React.useRef<number>();

  React.useEffect(() => {
    console.log("search change: ", search);
    if (search && search !== "") {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = window.setTimeout(() => {
        console.log("doSearch --");
        doSearch(search);
        searchTimeout.current = undefined;
      }, 1500);
    } else {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = undefined;
      setData(alTreeData.current);
      setSelectItems([]);
      setExpanded(allIds);
    }
  }, [search]);

  function doSearch(content: string) {
    setSelectItems([]);
    const selectIds: Set<string> = new Set<string>();
    const getMatchItems = (items?: UserTreeData[]) => {
      if (!items || items.length === 0) {
        return null;
      } else {
        return items
          .map((m) => {
            let matchItem = getMatchItems(m.children);
            let isCurrMatch = m.label.includes(content);
            if (isCurrMatch) {
              selectIds.add(m.id);
            }
            if (isCurrMatch || (matchItem !== null && matchItem?.length > 0)) {
              return m;
            } else {
              return null;
            }
          })
          .filter((m) => m !== null);
      }
    };

    // @ts-ignore
    setData(getMatchItems(data));
    setSelectItems(Array.from(selectIds));
  }

  async function fetchData() {
    const data = await UserByGroupApi.findAll();
    console.log("UserByGroupList: ", data);
    const treeData = toTree(true, data, undefined);
    console.log("treeData: ", treeData);
    setData(treeData);
    alTreeData.current = treeData;
    const allId = data?.map((d) => d.userId!);
    setAllIds(allId || []);
    setExpanded(allId || []);
  }

  function toTree(
    topLevel: boolean,
    models?: UserByGroupModel[],
    parentUserId?: string
  ): UserTreeData[] {
    if (
      !models ||
      models.length === 0 ||
      (!topLevel && parentUserId === undefined)
    ) {
      return [];
    }
    const currLevel = models.filter((m) => m.parentId === parentUserId);
    return currLevel.map((cl) => {
      return {
        ...cl,
        id: cl.userId,
        label: `${cl.uid} ${cl.name} ${cl.phone} ${cl.email}`,
        children: toTree(false, models, cl.userId),
      } as UserTreeData;
    });
  }

  function refresh() {
    fetchData();
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    refresh,
    search,
    changeSearch: setSearch,
    expanded,
    setExpanded,
    selectItems,
  };
}
