import { Delete } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand, BaseCommandProps } from "./BaseCommand";
import {
  useDeleteWithUndoController,
  useRecordContext,
  useResourceContext,
} from "ra-core";

export interface DeleteCommandProps extends CommandProps {}

DeleteCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "delete",
} as BaseCommandProps;

export function DeleteCommand(props: DeleteCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext();
  const { handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    redirect: "list",
  });
  return (
    <BaseCommand
      variant={"contained"}
      confirmTitle={"确认!"}
      confirmContent={`是否确定删除:${record.id}?`}
      command={{
        onHandle: async (e) => {
          handleDelete(e);
        },
        confirm: true,
        label: "删除",
        icon: <Delete />,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
