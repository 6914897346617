export enum AmountType {
  /**
   * 随机
   */
  RANDOM = "RANDOM",
  /**
   * 固定
   */
  AMOUNT = "AMOUNT",

  /**
   * 比例
   */
  RATIO = "RATIO",

  /**
   * 余额+ 补充订单= 总额
   */
  SUPPLEMENT = "SUPPLEMENT",
}

export const AmountTypeValueEnum = [
  {
    id: "RANDOM",
    name: "随机",
  },
  {
    id: "AMOUNT",
    name: "固定",
  },
  {
    id: "RATIO",
    name: "比例",
  },
  {
    id: "SUPPLEMENT",
    name: "补金",
  },
];

export enum CommissionType {
  /**
   * 比例
   */
  RATIO = "RATIO",

  /**
   * 固定金额
   */
  FIXED = "FIXED",
}

export const CommissionTypeValueEnum = [
  {
    id: "RATIO",
    name: "比例",
  },
  {
    id: "FIXED",
    name: "固定金额",
  },
];

export interface PayTasks {
  id: number;

  name: string;
  minAmount: string;
  /**
   * 完成后升级到的vip 级别
   * 0 默认不升级
   */
  upLevel: number;
  /**
   * 订单数量
   */
  orderCount: number;

  /**
   * 完成后的下一个任务
   */
  nextPayTasksId: number;

  tasks?: PayTask[];

  createdDate: string;

  updateDate: string;
}

export interface PayTask {
  amountType?: AmountType;

  amount: string;

  commissionType?: CommissionType;

  commissionValue?: string;

  intervalSec?: number;
}
