import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { OrderModel } from "../model/order";

export const OrderManagerApi = {
  freeze: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(`${API_URL}/order/freeze/${orderId}`, {
      method: "POST",
    });
    return data;
  },
  unfreeze: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(`${API_URL}/order/unfreeze/${orderId}`, {
      method: "POST",
    });
    return data;
  },
  skipUnfreeze: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(
      `${API_URL}/order/skipUnfreeze/${orderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  confirmPayment: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(
      `${API_URL}/order/confirmPayment/${orderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};
