import { Button } from "@mui/material";
import ConfirmDialog from "../core/ConfirmDialog";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { WithdrawManagerApi } from "../../api/WithdrawManagerApi";

function ManualPayBtn({ record }: { record: any }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await WithdrawManagerApi.manualPay(record.id);
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"error"}
        onClick={handleClickOpen}
      >
        手工出款
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"手工出款"}
        description={"确定手工出款吗? 此操作不会提交到通道"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default ManualPayBtn;
