import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { OrderModel } from "../model/order";

export const WithdrawManagerApi = {
  reject: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(
      `${API_URL}/withdrawRecord/reject/${orderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  makePay: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(
      `${API_URL}/withdrawRecord/makePay/${orderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  manualPay: async (orderId: number): Promise<OrderModel> => {
    const { data } = await fetchJson(
      `${API_URL}/withdrawRecord/manualPay/${orderId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};
