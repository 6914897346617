import { Button } from "@mui/material";
import ConfirmDialog from "../core/ConfirmDialog";
import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { OrderManagerApi } from "../../api/OrderManagerApi";

function FreezeBtn({ record }: { record: any }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (confirmed: boolean) => {
    if (confirmed) {
      await OrderManagerApi.skipUnfreeze(record.id);
      await refresh();
      notify("操作成功");
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"contained"}
        size={"small"}
        color={"error"}
        onClick={handleClickOpen}
      >
        跳过冻结订单
      </Button>
      <ConfirmDialog
        isOpen={open}
        title={"跳过冻结确认"}
        description={"确认跳沟冻订单结吗?"}
        onClose={(confirmed) => handleClose(confirmed)}
      />
    </>
  );
}

export default FreezeBtn;
