import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export interface UserAddressForm extends Record<string, any> {
  id?: string;
  userId: string;
  realName?: string;
  region?: string;
  telephone?: string;
  address?: string;
}

export interface UserAddress extends UserAddressForm {
  createdDate: string;
  updateDate?: string;
}

export const UserAddressManagerApi = {
  findByUserId: async (userId: string): Promise<UserAddress> => {
    const { data } = await fetchJson(
      `${API_URL}/userBankCard/findByUserId/${userId}`,
      {
        method: "GET",
      }
    );
    return data;
  },
  update: async (form: UserAddressForm): Promise<UserAddress> => {
    const { data } = await fetchJson(`${API_URL}/userBankCard/update`, {
      method: "POST",
      body: new URLSearchParams(form),
    });
    return data;
  },
};
