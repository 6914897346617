import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { CreditCard } from "@mui/icons-material";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";

const fields: MyField[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.userLevelField(),
  Field.userPayTaskField(),
  Field.text({
    label: "级别任务数",
    source: "levelTaskCount",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

export const UserLevelRecordCom = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  create: (record: any, onSuccess: (data: any, variables: any) => void) => {
    return (
      <MyCreate
        resource={"userLevelRecord"}
        fields={fields}
        record={record}
        redirect={false}
        mutationOptions={{ onSuccess: onSuccess }}
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CreditCard />,
};

export default resource;
